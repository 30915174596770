#experiencia {
  padding-top: 50px;
  padding-bottom: 50px;
  z-index: 1;
  background-color: #000;
  #title {
    margin-bottom: 30px;
    color: #ffffff;
  }
  #description,
  #pantallaCompleta {
    color: #ffffff;
  }
  #projectName {
    color: #ffffff;
    font-weight: 600;
    margin-top: 25px;
  }
  #visualizadorMasterplan {
    width: 100%;
    height: 300px;
  }
}
