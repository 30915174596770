#nosotros {
  padding-top: 70px;
  padding-bottom: 100px;
  z-index: 1;
  background-color: #ffffff;
  #title {
    margin-bottom: 30px;
    color: rgb(2, 61, 100);
  }
  #contentContainer {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
  }
  #riverImage {
    width: 600px;
  }
}
@media (max-width: 1200px) {
  #nosotros {
    #riverImage {
      display: none;
    }
  }
}
