#footer {
  background-color: rgba(61, 160, 31, 0.5);
  z-index: 3;
  #footer-content {
    width: 100%;
    text-align: center;
  }
  .footer-item {
    color: #ffffff;
  }
  .locationTitle {
    font-size: 20px;
  }
}
