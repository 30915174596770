@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  background-color: #ffffff !important;
  color: #202020 !important;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

//Navbar y footer:
.navbar {
  background-color: #202020;
}

h1,
h2,
h3 {
  font-family: "Maven Pro", sans-serif;
}

h4,
h5,
p,
li {
  font-family: "Public Sans", sans-serif;
}

h1,
h2 {
  font-weight: 700;
}

h3,
h4 {
  font-weight: 500;
}

h5,
p {
  font-weight: 200;
}

h1 {
  font-size: 50px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

p {
  font-size: 16px;
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}
