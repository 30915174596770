#navbar {
  background-color: rgb(1, 109, 182);
  font-family: "Public Sans", sans-serif;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
  #logo {
    width: 200px;
    height: auto;
  }
  .linksContainer {
    width: 100%;
    // display: flex;
    // justify-content: space-around;
  }
  .nav-link {
    color: #ffffff;
    font-size: 20px;
    margin-right: 10px;
    &:hover {
      color: rgb(148, 186, 211);
    }
  }
  .navbar-nav .show > .nav-link,
  .navbar-nav .nav-link.active {
    color: #ffffff;
  }
  #navbarSocials {
    display: flex;
    gap: 8px;
    margin-right: 18px;
    .socialLink {
      font-size: 24px;
      color: #ffffff;
      &:hover {
        color: rgb(148, 186, 211);
      }
    }
  }
}
