#backgroundImage {
  z-index: -9;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

@media (max-width: 1420px) {
  #backgroundImage {
    width: auto;
    height: 100vh;
  }
}
