#header {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  padding-top: 20px;
  padding-bottom: 200px;
  height: 700px;
  padding-bottom: 200px;
  .carousel {
    z-index: -8;
  } /* keeps this behind all content */
  .carousel .item {
    position: fixed;
    width: 100%;
    height: 100%;
  }
  .carouselImage {
    width: 100%;
    height: 800px;
  }
  #loader {
    display: flex;
    justify-content: center;
    padding-top: 200px;
    background-color: grey;
    width: 100%;
    height: 800px;
    z-index: -8;
  }
  #headerContent {
    margin-top: -700px;
    padding-bottom: 200px;
  }
  #preTitle {
    font-size: 100px;
  }
  #title {
    color: #ffffff;
    margin-top: 220px;
    font-size: 100px;
    text-shadow: 2px 2px 5px #202020;
    line-height: 60px;
    margin-bottom: 25px;
  }
  #subtitle {
    font-size: 40px;
    color: #ffffff;
    text-shadow: 2px 2px 5px #202020;
  }
  .homeButton {
    width: 200px;
    margin-top: 40px;
    margin-right: 20px;
    margin-bottom: 60px;
  }
  #moreInfoButton {
    background-color: rgb(0, 142, 236, 0.5);
    &:hover {
      color: rgba(154, 215, 255);
      border-color: rgba(154, 215, 255);
    }
  }
  #homeContactButton {
    background-color: rgb(1, 109, 182);
    color: #ffffff;
    border-color: rgb(1, 109, 182);
    &:hover {
      background-color: rgb(3, 90, 148);
      border-color: rgb(3, 90, 148);
    }
  }

  .generalTitle {
    margin-top: 25px;
  }
}

@media (max-width: 991px) {
  #header {
    height: 600px;
    .carouselImage {
      width: 100%;
      height: 600px;
    }
    #loader {
      height: 600px;
      padding-top: 150px;
    }
    #headerContent {
      margin-top: -600px;
      padding-bottom: 100px;
    }
    #preTitle {
      font-size: 80px;
    }
    #title {
      font-size: 80px;
      margin-bottom: 15px;
    }
    #subtitle {
      font-size: 28px;
    }
  }
}

@media (max-width: 700px) {
  #header {
    height: 450px;
    .carouselImage {
      width: 100%;
      height: 450px;
    }
    #loader {
      padding-top: 120px;
      height: 450px;
    }
    #headerContent {
      margin-top: -500px;
      padding-bottom: 100px;
    }
    #preTitle {
      font-size: 60px;
    }
    #title {
      font-size: 60px;
      margin-bottom: 15px;
    }
    #subtitle {
      font-size: 20px;
    }
    .homeButton {
      margin-top: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 458px) {
  #header {
    .carouselImage {
      height: 550px;
    }
    #loader {
      padding-top: 120px;
      height: 500px;
    }
    #headerContent {
      margin-top: -650px;
    }
  }
}
