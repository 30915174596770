#videos {
  padding-top: 70px;
  padding-bottom: 70px;
  z-index: 1;
  background-color: #ffffff;
  #title {
    margin-bottom: 30px;
    color: rgb(2, 61, 100);
  }

  #youtubeListContainer {
    width: 100%;
    text-align: center;
  }
}
