#proyectos {
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
  padding-top: 60px;
  padding-bottom: 80px;
  position: relative;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: "../../assets/img/greywood.jpeg";
  height: 100%;
  #contentContainer {
    z-index: 2;
    opacity: 1;
  }
  #backgroundColor {
    width: 100%;
    height: 100%;
    background-color: rgba(61, 160, 31, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  #title {
    margin-bottom: 30px;
    color: #ffffff;
  }
  #containerProyectos {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: space-evenly;
  }
  // #cardHeader {}
  .card-img,
  .card-img-top {
    width: 100.5%;
    height: 150px;
    margin-left: -1px;
    margin-top: -1px;
  }
  #cardHeader {
    width: 22rem;
  }
  #cardAlert {
    width: 120px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin: 0;
    }
  }
  .classTitle {
    color: #0d4e64;
    font-weight: 500;
    font-family: "Maven Pro", sans-serif;
    font-size: 22px;
  }
  .cardButton {
    color: #ffffff;
    background-color: rgb(1, 109, 182);
    &:hover {
      background-color: rgb(3, 90, 148);
      border-color: rgb(3, 90, 148);
    }
  }
  .filling-empty-space-childs {
    width: 22rem;
    height: 0;
  }
  .commingAlert {
    background-color: rgb(81, 184, 253, 0.9);
    margin-top: -2px;
    color: #ffffff;
  }
  .selledAlert {
    margin-top: -2px;
    background-color: rgb(233, 124, 138, 0.9);
    color: #ffffff;
  }
}
#navigationButtonsContainer {
  display: flex;
  gap: 20px;
  .navigationButton {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}

@media (max-width: 1400px) {
  #proyectos {
    #cardHeader,
    .filling-empty-space-childs {
      width: 22rem;
    }
  }
}
@media (max-width: 1200px) {
  #proyectos {
    #cardHeader,
    .filling-empty-space-childs {
      width: 17rem;
    }
    #containerGProyectos {
      gap: 10px;
    }
  }
}

@media (max-width: 992px) {
  #proyectos {
    #cardHeader,
    .filling-empty-space-childs {
      width: 19rem;
    }
    .classTitle {
      color: #0d4e64;
      font-weight: 500;
      font-family: "Maven Pro", sans-serif;
      font-size: 22px;
    }
    .card-img,
    .card-img-top {
      height: 180px;
    }
  }
}

@media (max-width: 767px) {
  #proyectos {
    #cardHeader,
    .filling-empty-space-childs {
      width: 100%;
    }
  }
}
